<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12">
        <v-card id="card-custom" class="px-2 py-4 rounded-lg">
          <v-card-actions>
            <p class="headline-color px-2">
              Data Pemanggilan Pegawai Pelanggar Disiplin (Hari ini)
            </p>
          </v-card-actions>
          <v-divider />
          <v-row class="mx-2 my-5">
            <v-col cols="12" xl="3" lg="3" md="3" sm="6" xs="12">
              <v-select
                placeholder="Sub Bagian"
                :items="items"
                outlined
                dense
                hide-details
                clearable
                item-text="title"
                item-value="title"
              ></v-select>
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="6" xs="12">
              <v-btn color="#FBB005" class="px-10">
                <span class="subtitle-2 text-capitalize">Cari</span>
              </v-btn>
            </v-col>
            <v-col cols="12" xl="7" lg="7" md="7" sm="12" xs="12" class="pt-5">
              <v-btn
                text
                small
                color="primary"
                class="float-right"
                @click="isWaiting = !isWaiting"
                >{{
                  isWaiting ? "ARSIP PEMANGGILAN" : "ARSIP PEMANGGILAN TERTUNDA"
                }}</v-btn
              >
            </v-col>
          </v-row>
          <v-data-table
            v-if="isWaiting"
            id="table-custom"
            :headers="headers"
            :items="employees"
            :items-per-page="5"
          >
            <template v-slot:[`item.nip`]="{ item }">
              <span
                class="hover-primary"
                @click="getToPage('presensi.detail')"
                >{{ item.nip }}</span
              >
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                text
                small
                @click="handleCall(item)"
                color="primary"
                class="text-capitalize"
                >Panggil</v-btn
              >
            </template>
          </v-data-table>
          <v-data-table
            v-else
            id="table-custom"
            :headers="headers"
            :items="employees"
            :items-per-page="5"
          >
            <template v-slot:[`item.nip`]="{ item }">
              <span
                class="hover-primary"
                @click="getToPage('presensi.detail')"
                >{{ item.nip }}</span
              >
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                text
                small
                @click="handleAgree(item)"
                color="primary"
                class="text-capitalize"
                >Setujui</v-btn
              >
              <v-btn
                text
                small
                @click="handleCancel(item)"
                color="primary"
                class="text-capitalize"
                >Tunda</v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      units: ["Kenaikan Pangkat", "Penurunan Pangkat"],
      isWaiting: false,
      items: [
        { title: "Satker Pusat" },
        { title: "Satker Khusus" },
        { title: "Satker Perangkat Daerah (SKPD)" },
        { title: "Satker Non-Vertikal Tertentu (SNVT)" },
        { title: "Satker Sementara (SKS)" }
      ],
      headers: [
        { text: "NIP", value: "nip", sortable: false, align: "center" },
        {
          text: "ID PRESENSI",
          value: "id_presensi",
          sortable: false,
          align: "center"
        },
        { text: "NAMA", value: "nama", sortable: false, align: "center" },
        { text: "JABATAN", value: "jabatan", sortable: false, align: "center" },
        { text: "", value: "actions", sortable: false, align: "center" }
      ],
      employees: [
        {
          nip: "123456789101112",
          id_presensi: "11-223344",
          nama: "Arif Wicaksono",
          jabatan: "Staff Bag Tata Usaha Direktorat SMP"
        },
        {
          nip: "123456789101112",
          id_presensi: "11-223344",
          nama: "Budi Kurniawan",
          jabatan: "Staff Bag Tata Usaha Direktorat SMP"
        },
        {
          nip: "123456789101112",
          id_presensi: "11-223344",
          nama: "Nadia Karimah",
          jabatan: "Staff Bag Tata Usaha Direktorat SMP"
        },
        {
          nip: "123456789101112",
          id_presensi: "11-223344",
          nama: "Rosalinda",
          jabatan: "Staff Bag Tata Usaha Direktorat SMP"
        },
        {
          nip: "123456789101112",
          id_presensi: "11-223344",
          nama: "Hermawan",
          jabatan: "Staff Bag Tata Usaha Direktorat SMP"
        }
      ]
    };
  },
  methods: {
    handleAgree() {},
    handleCancel() {},
    handleCall() {}
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead {
    background-color: #fdfdfd;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
    font-weight: 400;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
</style>
